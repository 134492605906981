import React from 'react';
import { PortableText } from '@portabletext/react';

import Icon1 from '@/assets/cs-icon-1.svg';
import Icon2 from '@/assets/cs-icon-2.svg';
import Icon3 from '@/assets/cs-icon-3.svg';

const portableText = {
  block: {
    normal: ({ children }) => (
      <p className="text-xl font-normal text-black">{children}</p>
    ),
  },
  marks: {
    strong: ({ children }) => <span className="font-bold">{children}</span>,
  },
};

const CaseStudiesHero = ({ data }) => {
  const { titleHighlighted, titleNormal, description } = data;

  return (
    <div className="relative z-10">
      {/* dotted backgrounds */}
      <div className="dotted-background left-0 top-0 h-full w-[30%]" />
      <div className="dotted-background right-0 top-0 h-full w-[30%]" />

      {/* gradient backgrounds */}
      {/* <div className="absolute right-0 top-0 -z-10 h-2/4 w-1/3 bg-gradient-hero blur-[7rem]" />
      <div className="absolute -bottom-10 left-0 -z-10 h-1/2 w-1/3 bg-gradient-hero blur-[7rem]" /> */}

      <section className="relative mx-auto max-w-[90rem] px-5 py-10 lg:py-32">
        {/* left side icons */}
        {/* <Icon2 className="absolute left-2 top-2 md:left-12 md:top-6 lg:left-14 lg:top-12" />
        <Icon1 className="absolute bottom-0 left-2 top-0 my-auto md:left-8 md:top-10 lg:left-2 lg:top-20" />
        <Icon3 className="absolute bottom-2 left-2 md:bottom-2 md:left-16 lg:bottom-2 lg:left-52" /> */}

        {/* right side icons */}
        {/* <Icon1 className="absolute right-2 top-2 md:right-20 md:top-4 lg:right-14 lg:top-12" />
        <Icon2 className="absolute bottom-2 right-2 md:bottom-2 md:right-12 lg:bottom-8 lg:right-44" />
        <Icon3 className="absolute bottom-0 right-2 top-0 my-auto md:bottom-6 md:right-4 lg:right-2 lg:top-20" /> */}

        <div className="relative m-auto text-center">
          {/* Title */}
          <h1 className="my-6 text-4xl leading-tight text-black md:block md:text-5xl lg:text-[3.5rem]">
            <span className="bg-gradient-to-r from-rm to-pb bg-clip-text text-transparent">
              {titleHighlighted}
            </span>{' '}
            {titleNormal}
          </h1>
          {/* Text */}
          <div className="m-auto max-w-[600px]">
            <PortableText
              value={description}
              components={portableText as any}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default CaseStudiesHero;
