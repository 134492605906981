import Card from './card';
import React from 'react';

const CaseStudiesList = ({
  items,
  cardLinkLabel,
}: {
  items: any[];
  cardLinkLabel: string;
}) => {
  const firstHighlightedItemIndex = items.findIndex((item) => item.standout);
  const firstHighlightedItem = items[firstHighlightedItemIndex];
  const orderedItems = [
    firstHighlightedItem,
    ...items.filter((item) => item.id !== firstHighlightedItem.id),
  ];

  return (
    <section className="mx-auto max-w-[90rem] px-5 py-5 lg:py-14">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:gap-8">
        {orderedItems.sort().map((item) => (
          <Card
            item={item}
            cardLinkLabel={cardLinkLabel}
            key={item.slug.current}
          />
        ))}
      </div>
    </section>
  );
};

export default CaseStudiesList;
