import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { HiArrowRight } from 'react-icons/hi';

const CTALink = ({ slug, label, className }) => (
  <Link to={`/${slug}`} className={className}>
    {label}
    <HiArrowRight />
  </Link>
);

type Props = {
  item: any;
  cardLinkLabel: string;
  cardClassName?: string;
};

const CaseStudyCard = ({ item, cardLinkLabel, cardClassName }: Props) => {
  return (
    <div
      className={`${item.standout ? `sm:col-span-2` : 'col-span-1'} relative h-[300px] overflow-hidden rounded-2xl ${cardClassName}`}
    >
      {/* main image */}
      <div className="absolute inset-0 flex h-full w-full items-center justify-center">
        <GatsbyImage
          image={item.mainImage.asset.gatsbyImageData}
          alt={item.mainImageAlt}
          imgClassName="w-full h-full object-cover"
          className="h-full w-full max-w-none"
        />
        {/* subtle overlay */}
        <div className="absolute inset-0 z-10 bg-black/10"></div>
      </div>

      {/* button */}
      {item.isClickable && (
        <div
          className={`absolute bottom-0 left-0 right-0 z-20 bg-gradient-to-t from-${item.heroColor}/70 to-transparent px-4 py-6`}
        >
          <CTALink
            slug={`case-studies/${item.slug.current}`}
            label={cardLinkLabel}
            className="flex items-center gap-2 text-base text-white hover:opacity-80"
          />
        </div>
      )}

      {/* logo image */}
      <img
        src={item.clientLogo.asset.url}
        alt={item.mainImageAlt}
        className="absolute left-6 top-6 z-20 h-8 w-auto"
      />
    </div>
  );
};

export default CaseStudyCard;
